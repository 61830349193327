<template>
  <div class="animated fadeIn vendor-create">
    <b-card>
      <div>
      <h4 class="invoice-title mb-2"><i class="fal fa-edit"></i> Add Sub Vendors </h4></div>
        <b-row class="mb-4 clearfix">
          <b-col md="6" lg="9">
          <form @submit.prevent="validateBeforeSubmit">
            
                <p class="mt-1">Put NA if you don't have this information, you can edit that later.</p>
                <h4 class="text-muted mt-3 mb-0 pb-0">Name</h4>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-1">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
                    </b-input-group>
                    <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-1">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
                    </b-input-group>
                    <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
                  </b-col>
                </b-row>

                <hr>
                <h4 class="text-muted mt-1 mb-0 pb-0">Business Information</h4>

                <b-row>
                  <b-col md="12">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="company_name" v-validate="'required'" name="company_name" class="form-control"  readonly placeholder="Company Name" />
                    </b-input-group>
                    <i v-show="errors.has('company_name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('company_name')" class="help is-danger">The company name is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="addressline1" v-validate="'required'" name="addressline1" readonly class="form-control" placeholder="Address Line 1" />
                    </b-input-group>
                    <i v-show="errors.has('addressline1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addressline1')" class="help is-danger">The address is required. Must contain numbers and letters.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="addressline2" name="addressline2"  readonly class="form-control" placeholder="Address Line 2" />
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="city" v-validate="'required'" name="city" readonly class="form-control" placeholder="City" />
                    </b-input-group>
                    <i v-show="errors.has('city')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('city')" class="help is-danger">The city is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select type="select" :options="provinceOptions" v-model="province" readonly v-validate="'required'" name="province" class="form-control" placeholder="Province"/>
                    </b-input-group>
                    <i v-show="errors.has('province')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('province')" class="help is-danger">The province is required. Max 6 characters.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="postalcode" v-validate="'required|max:6'" readonly  name="postalcode" class="form-control" placeholder="Postal Code" />
                    </b-input-group>
                    <i v-show="errors.has('postalcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('postalcode')" class="help is-danger">The postal code is required.</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="rcn" name="rcn" class="form-control"  placeholder="Registration Company Number" readonly />
                    </b-input-group>
                    <i v-show="errors.has('rcn')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('rcn')" class="help is-danger">The company number is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="vidcode" name="vidcode" class="form-control" placeholder="Vendor ID Code"  readonly />
                    </b-input-group>
                    <i v-show="errors.has('vidcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('vidcode')" class="help is-danger">The vendor ID code is required.</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="gst" name="gst" class="form-control" placeholder="Vendor GST Number" readonly />
                    </b-input-group>
                    <i v-show="errors.has('gst')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('gst')" class="help is-danger">The vendor GST number is required.</span>
                  </b-col>
                </b-row>
                <hr>
                <h4 class="text-muted mt-1 mb-0 pb-0">Banking Information</h4>
                <b-row>
                  <b-col md="4">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="bankbranch" name="bankbranch" class="form-control" placeholder="Branch Number"  readonly />
                    </b-input-group>
                    <i v-show="errors.has('bankbranch')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('bankbranch')" class="help is-danger">The branch number is required.</span>
                  </b-col>
                  <b-col md="4">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="bankins" name="bankins" class="form-control" placeholder="Institution Number" readonly />
                    </b-input-group>
                    <i v-show="errors.has('bankins')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('bankins')" class="help is-danger">The bank institution number is required.</span>
                  </b-col>
                  <b-col md="4">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="banknumber" name="banknumber" class="form-control" placeholder="Account Number" readonly />
                    </b-input-group>
                    <i v-show="errors.has('banknumber')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('banknumber')" class="help is-danger">The bank account number is required.</span>
                  </b-col>
                </b-row>
                <hr>
                <h4 class="text-muted mt-3 mb-3 pb-0">Contact Information</h4>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="phone_num" v-validate="'required|digits:10'" name="phone_num" class="form-control" placeholder="Phone Number" />
                    </b-input-group>
                    <i v-show="errors.has('phone_num')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone_num')" class="help is-danger">The phone number is required. Must be 10 digits.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text>@</b-input-group-text>
                      </b-input-group-prepend>
                      <input type="email" class="form-control" v-model="email" v-validate="'required|email'" placeholder="Email" name="email" autocomplete="email" />
                    </b-input-group>
                    <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
                  </b-col>
                </b-row>
                <hr>
                
                <h4 class="text-muted mt-3 mb-3 pb-0">Vendor Creation Authority</h4>
                <b-row>
                   <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select type="select" :options="creationOptionList" v-model="creationauthority" v-validate="'required'" name="creation" class="form-control" placeholder="Enter Authority Type"/>
                    </b-input-group>
                    <i v-show="errors.has('creation')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('creation')" class="help is-danger">Authority is required</span>
                  </b-col>
                </b-row>
                <hr>

                  <input hidden type="text" v-model="parentvendor" readonly>

                <p><i v-show="error" class="fa fa-exclamation-triangle"></i><span v-show="error" class="help is-danger">Signup failed. Please contact admin.</span></p>
                <b-button variant="primary" class="mt-4" type="submit" block>Create Account</b-button>
              </form>
          </b-col>
          
        </b-row>
 
      </b-card>
  </div>
</template>

<script>
export default {
  name: 'RegisterVendor',
  components: {
    
  },
  data: function () {
    return {
      robot: '',
      vidcode: '',
      email: '',
      fname: '',
      lname: '',
      company_name: '',
      rcn: '',
      phone_num: null,
      fax_num: null,
      gst: '',
      postalcode: '',
      parentvendor:null,
      creationauthority:null,
      bankaccount: '',
      bankins: '',
      banknumber: '',
      bankbranch: '',
      password: '',
      password2: '',
      error: '',
      province: null,
      city: '',
      addressline1: '',
      
      addressline2: '',
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: "Alberta", text: 'Alberta'},
        { value: "British Columbia", text: 'British Columbia'},
        { value: "Manitoba", text: 'Manitoba'},
        { value: "New Brunswick", text: 'New Brunswick'},
        { value: "Newfoundland and Labrador", text: 'Newfoundland and Labrador'},
        { value: "Nova Scotia", text: 'Nova Scotia'},
        { value: "Ontario", text: 'Ontario'},
        { value: "Prince Edward Island", text: 'Prince Edward Island'},
        { value: "Quebec", text: 'Quebec'},
        { value: "Saskatchewan", text: 'Saskatchewan'},
        { value: "Northwest Territories", text: 'Northwest Territories'},
        { value: "Nunavut", text: 'Nunavut'},
        { value: "Yukon", text: 'Yukon'}
      ],
      creationOptionList: [
        { value: null, text: 'Please select a authority Type' },
        { value: "0", text: 'No'},
        { value: "1", text: 'Yes'}
      ]
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.bankaccount = this.bankins + "-" + this.bankbranch + "-" + this.banknumber;
          let data = JSON.stringify({
            vidcode: this.vidcode,
            email: this.email,
            fname: this.fname,
            lname: this.lname,
            company_name: this.company_name,
            rcn: this.rcn,
            password: this.password,
            phone_num: this.phone_num,
            fax_num: this.fax_num,
            gst: this.gst,
            province: this.province,
            city: this.city,
            addressline1: this.addressline1,
            addressline2: this.addressline2,
            postalcode: this.postalcode,
            bankaccount: this.bankaccount,
            parentvendor:this.parentvendor,
            creationauthority:this.creationauthority
          })

          this.$axios.post('/vendor/subvendor/save', data)
          .then(response => {
            if (response.status != 200) {
              this.$toasted.show('Error.', {type: 'error', duration: '3000'})
              this.error = true;
            }
            if (response.status === 200) {
              this.flash({ message: 'Registration Successful', variant: 'success' });
              this.$router.push('/')
            }
          })
          .catch(error => {
            this.error = true;
            this.robot = '';
          })
          return;
        }
      });
    },
    getParentVendorInfo()
    {
        this.$axios.get('/vendor/subvendor/getparentinfo')
          .then(response => {

                  this.parentvendor=response.data.vid;
                  this.company_name=response.data.company_name;
                  this.addressline1=response.data.addressline1;
                  this.addressline2=response.data.addressline2;
                  this.city=response.data.city;
                  this.province=response.data.province;
                  this.postalcode=response.data.postalcode;
                  this.rcn=response.data.rcn;
                  this.vidcode=response.data.vidcode;
                  this.gst=response.data.gst;
                  this.bankbranch=response.data.bankaccount.split('-')[0];;
                  this.bankins=response.data.bankaccount.split('-')[1];;
                  this.banknumber=response.data.bankaccount.split('-')[2];;
          

          });
    }
  },
   // Write method above this aprt



   // This is start of mounted section
   mounted: function() {

          this.getParentVendorInfo();
    
    }
  // End of mounted section

}
</script>